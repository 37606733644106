/* src/App.css */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f0fff0; /* Very light green (almost white) */
  color: #004d00; /* Dark dark green text */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Main view styling */
.main-view {
  padding: 20px;
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.language-switch {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 10px;
  background-color: #007BFF;
  color: white;
  border: none;
  cursor: pointer;
}

.medium-title {
  text-align: center;
  font-size: 3em;
  margin-top: 20px;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
}

.text-content {
  max-width: 600px;
  text-align: justify;
  margin-bottom: 20px;
}

.big-title {
  font-size: 2.2em;
  margin-bottom: 10px;
}

.description {
  font-size: 1.6em;
  margin-bottom: 10px;
}

.placeholder-image {
  width: 100%;
  max-width: 400px;
  margin-top: 20px;
}

.contact-section {
  text-align: center;
  margin-top: 40px;
}

.highlight-email {
  color: #006400; /* Dark green */
  font-weight: bold;
}

.footer {
  margin-top: 40px;
  padding: 20px;
  background-color: #004d00; /* Dark green */
  color: white;
  text-align: center;
  width: 100%;
}

.footer-link {
  color: white;
  margin: 0 10px;
  text-decoration: none;
}

.footer-link:hover {
  text-decoration: underline;
}

@media (min-width: 768px) {
  .content-wrapper {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .text-content {
    text-align: justify;
    margin-bottom: 0;
  }

  .placeholder-image {
    margin-top: 0;
    margin-left: 20px;
  }
}

.app-link-button {
  display: block;
  text-align: center;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1em;
  width: 150px;
}

/* Additional styling for other pages */
.privacy-policy, .user-data-deletion, .terms-of-service {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  text-align: justify;
}

.privacy-policy h1, .user-data-deletion h1, .terms-of-service h1 {
  text-align: center;
}

.privacy-policy p, .user-data-deletion p, .terms-of-service p {
  margin-bottom: 20px;
}
